import React from 'react';
import styles from './Checkbox.module.scss';
import { ICheckbox } from './ICheckbox';

const Checkbox: React.FC<ICheckbox> = ({
  name, label, checked, onChange, main,
}) => (
  <>
    <input
      type="checkbox"
      id={name}
      name={name}
      className={main ? styles.main : styles.input}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={name}>{label}</label>
  </>
);

export default React.memo(Checkbox);
