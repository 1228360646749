import { BaseService } from './BaseService';

export class PurchaseService extends BaseService {
  async createOrder(): Promise<{ cardPaymentUrl: string; paypalPaymentUrl: string }> {
    const orderData = await this.httpClient.post('/orders', {
      order_data: {
        subjects: [
          {
            assigned_to: 'partner_1',
            birth_place: {
              longitude: 101.6726903,
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
            },
            gender: 'male',
            birth_date: '1976-04-23',
          },
          {
            birth_place: {
              place: 'Kuala Lumpur, KL, Malaysia',
              latitude: 3.135985,
              longitude: 101.6726903,
            },
            birth_date: '1986-05-10',
            gender: 'female',
            assigned_to: 'partner_2',
            birth_time: '4:08',
          },
        ],
      },
      email: 'alekzo1992@gmail.com',
      offer_name: 'subscription_premium_1w_9.99',
      project: 'Nebula',
    });

    await this.httpClient.post(`/orders/${orderData.order_id}`, {
      offer_name: 'subscription_premium_1w_9.99',
    });

    const paymentsData = await this.httpClient.get(`/orders/${orderData.order_id}/payments`);

    return {
      cardPaymentUrl: paymentsData.payment_form,
      paypalPaymentUrl: paymentsData.paypal_payment_script,
    };
  }
}
