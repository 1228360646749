import React, { FC } from 'react';
import { ICardPayment } from './ICardPayment';

const CardPayment: FC<ICardPayment> = ({
  formUrl,
}) => (
  <iframe title="card" height="350px" src={formUrl} width="100%" />
);

export default CardPayment;
