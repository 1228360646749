import { combineReducers } from 'redux';
import { cookiesAgreementReducer } from '../cookiesAgreement';

const rootReducer = combineReducers({
  cookiesAgreement: cookiesAgreementReducer,
});

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
