import React from 'react';

import styles from './PackageDescription.module.scss';

const contents = [
  'Compatibility analysis for TAURUS',
  'Detailed guide to relationship with other signs',
  'Astrology-powered advice',
  'Horoscope for 2021',
  'Premium account in Astrology App',
];

const PackageDescription = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>What’s inside?</h3>
    <ul className={styles.list}>
      {contents.map((text) => (
        <li key={text} className={styles.list_item}>
          {text}
        </li>
      ))}
    </ul>
  </div>
);

export default PackageDescription;
