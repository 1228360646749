import React from 'react';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import usePurchase from 'src/hooks/usePurchase';
import { IPopUpProps } from 'src/components/PopUpContainer/IPopUpProps';
import PaypalPayment from '../PaypalPayment/PaypalPayment';
import CardPayment from '../CardPayment/CardPayment';
import styles from './Purchase.module.scss';

const Purchase: React.FC<IPopUpProps> = ({ isOpened, onClose }) => {
  const {
    isLoaded,
    isOrderProcessing,
    cardPaymentUrl,
    paypalRef,
    paypalButtonRef,
  } = usePurchase();

  const handleClose = () => {
    onClose();
  };

  const paymentContainerStyles = classNames({
    [styles.payments_container]: true,
    [styles.hidden]: !isLoaded || isOrderProcessing,
  });

  const titlePrice = (
    <p className={styles.price}>
      Total:
      <span>$9.99</span>
    </p>
  );

  const titleLoading = (
    <p className={styles.price}>
      <span>{isOrderProcessing ? 'Processing order' : 'Loading order'}</span>
      <CircularProgress
        size={18}
        classes={{
          circle: styles.spinner,
        }}
      />
    </p>
  );

  const showTitleLoading = !isLoaded || isOrderProcessing;

  return (
    <Modal
      className={styles.modal}
      open={isOpened}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(60, 60, 60, 0.1)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.button} type="button" onClick={handleClose}>
            <CloseIcon />
          </button>
          {showTitleLoading ? titleLoading : titlePrice}
        </div>
        <div className={paymentContainerStyles}>
          <PaypalPayment
            paypalRef={paypalRef}
            paypalButtonRef={paypalButtonRef}
          />
          <p className={styles.text}>or</p>
          <CardPayment formUrl={cardPaymentUrl} />
        </div>
      </div>
    </Modal>
  );
};

export default Purchase;
