import React from 'react';
import secure from '../../../assets/images/postQuiz/advantages/secure.svg';
import privat from '../../../assets/images/postQuiz/advantages/private.svg';
import trust from '../../../assets/images/postQuiz/advantages/trust.svg';
import gifted from '../../../assets/images/postQuiz/advantages/gifted.svg';
import anytime from '../../../assets/images/postQuiz/advantages/anytime.svg';
import satisfaction from '../../../assets/images/postQuiz/advantages/satisfaction.svg';
import AdvantagesItem from '../AdvantagesItem/AdvantagesItem';
import styles from './AdvantagesList.module.scss';

const list = [
  {
    image: secure,
    alt: 'secure',
    title: 'Secure online payments',
    text: 'All payments are encrypted with the advanced security software.',
  },
  {
    image: privat,
    alt: 'private',
    title: 'Private and safe place',
    text: 'Your conversations and readings are always 100% anonymous and confidential.',
  },
  {
    image: trust,
    alt: 'trust',
    title: 'Trusted brand',
    text: 'Lunar is trusted by thousands of customers since 2015.',
  },
  {
    image: gifted,
    alt: 'gifted experts',
    title: 'Gifted Experts',
    text: 'Over 1000+ experts are ready to help you find answers and get valuable guidance.',
  },
  {
    image: anytime,
    alt: 'anytime',
    title: 'Anytime, Anywhere',
    text: "We're here for you whenever you need help or guidance.",
  },
  {
    image: satisfaction,
    alt: 'satisfaction guarantee',
    title: 'Satisfaction Guarantee',
    text: 'If you are not satisfied with the service you can get your money back.',
  },
];

const AdvantagesList = () => (
  <div className={styles.list}>
    {list.map(({
      image, alt, title, text,
    }) => (
      <AdvantagesItem key={alt} image={image} alt={alt} title={title} text={text} />
    ))}
  </div>
);

export default AdvantagesList;
